import React from 'react'
import { AnimatePresence, motion } from 'framer-motion'

import { colors } from '../../constants/colors'
import { Type } from '../../elements/Typography/Typography'
import * as styles from './styles'

const PaginationItem = ({
  title,
  value,
  active,
  ariaLabel,
  disabled,
  routeWhenSelected,
  steps = 1,
  activeStep = 0,
  onClick,
  themeStyle,
}) => {
  return (
    <styles.PaginationItem
      hasValue={!disabled && !!value}
      themeStyle={themeStyle}
    >
      <styles.Button
        data-test={`breadcrumb-${routeWhenSelected}`}
        onClick={onClick}
        disabled={disabled}
        aria-label={ariaLabel || title}
        type="button"
        themeStyle={themeStyle}
      >
        {title && (
          <styles.Text
            preset="labelSmall"
            weight="normal"
            disabled={disabled}
            themeStyle={themeStyle}
          >
            {title}
          </styles.Text>
        )}
        <styles.Value>
          <AnimatePresence initial={false}>
            {!disabled && !!value && (
              <motion.div
                initial={{ opacity: 0, y: 15 }}
                animate={{ opacity: 1, y: 0, transition: { delay: 0.3 } }}
                exit={{ opacity: 0, y: 15 }}
                transition={{ duration: 0.3 }}
              >
                <Type
                  preset="labelSmall"
                  weight="normal"
                  style={{
                    color: themeStyle?.textColor ?? colors.deepBlue,
                    whiteSpace: 'nowrap',
                  }}
                >
                  {value}
                </Type>{' '}
              </motion.div>
            )}
          </AnimatePresence>
        </styles.Value>

        <styles.Line disabled={disabled} themeStyle={themeStyle}>
          {Array.from({ length: steps }, (_, index) => (
            <styles.Dash
              key={index}
              disabled={disabled}
              themeStyle={themeStyle}
            >
              <styles.ActiveIndicator
                active={active && index <= activeStep}
                disabled={disabled}
                themeStyle={themeStyle}
              />
            </styles.Dash>
          ))}
        </styles.Line>
      </styles.Button>
    </styles.PaginationItem>
  )
}

/**
 * @typedef {Object} Step
 * @property {string[]} routes
 * @property {string} title
 * @property {boolean} isDisabled
 */

/**
 * @typedef {Object} PaginationProps
 * @property {Step[]} steps
 * @property {string} currentRoute
 * @property {(string) => void} onClick
 * @property {boolean} [alwaysShowValues]
 */

/**
 *
 * @param {number} index
 * @param {number} activeStepIndex
 */
const isActive = (index, activeStepIndex) => index <= activeStepIndex

const subStepIndex = (
  currentStep,
  stepIndex,
  activeStepIndex,
  currentRoute
) => {
  const numberOfSteps = currentStep.routes.length

  // Return 0 if greater than current step
  if (stepIndex > activeStepIndex) {
    return 0
  }

  // If already finished this step, activate all substeps
  if (stepIndex < activeStepIndex) {
    return numberOfSteps - 1
  }

  // Current step is a list of steps
  if (numberOfSteps > 1) {
    // Find the index we are at
    return currentStep.routes.indexOf(currentRoute)
  }

  return 0
}

/**
 *
 * @param {PaginationProps} props
 */
export const Pagination = ({
  steps = [],
  currentRoute,
  onClick,
  alwaysShowValues = false,
  themeStyle = undefined,
}) => {
  const onSelectStep = step => onClick(step)

  let activeStepIndex = 0

  steps.forEach((step, index) => {
    if (step.routes.includes(currentRoute)) {
      activeStepIndex = index
    }
  })

  return (
    <div role="navigation">
      <styles.Pagination>
        {steps.map((step, index) => {
          const routeWhenSelected = step.routes[0]
          return (
            <PaginationItem
              key={`${step.title}-${index}`}
              title={step.title}
              value={
                index < activeStepIndex || alwaysShowValues ? step.value : ''
              }
              active={isActive(index, activeStepIndex)}
              disabled={step.isDisabled}
              ariaLabel={step.ariaLabel}
              steps={step.routes.length}
              activeStep={subStepIndex(
                step,
                index,
                activeStepIndex,
                currentRoute
              )}
              routeWhenSelected={routeWhenSelected}
              onClick={() => onSelectStep(routeWhenSelected)}
              themeStyle={themeStyle}
            />
          )
        })}
      </styles.Pagination>
    </div>
  )
}
